<template>
  <component
    :is="is"
    :title="props.title"
    v-bind="{
      ...(props.to ? { to: props.to } : {}),
      ...(!props.to && props.href ? { href: props.href } : {}),
      ...(props.disabled && is === 'button' ? { disabled: 'disabled' } : {})
    }"
    :class="[
      { 'inline-block flex items-center justify-start underline': link },
      { 'inline-block font-semibold focus:outline-none text-center flex items-center justify-center transition-all duration-300 ease-in-out ui-button': !link },
      { 'bg-cta-500 hover:bg-cta-700 border-solid border border-cta-500 hover:border-cta-700 text-white': cta },
      { 'bg-transparent hover:bg-petrol-500 border-solid border border-petrol-500/100 text-petrol-500 hover:text-white fill-petrol-500 hover:fill-white': primary },
      { 'bg-transparent hover:bg-petrol-900 border-solid border border-petrol-900/100 text-petrol-900 hover:text-white fill-petrol-900 hover:fill-white': primaryHighContrast },
      { 'bg-petrol-500 hover:bg-petrol-700 border-solid border border-petrol-500/100 text-white': petrol },
      { 'bg-orange-500 hover:bg-orange-600 text-white': orange },
      { 'bg-green-500 hover:bg-green-600 text-white': success },
      { 'bg-red-500 hover:bg-red-600 text-white': error },
      { 'bg-neutral-50 hover:bg-neutral-200 text-black': light },
      { 'bg-neutral-50 hover:bg-neutral-50 active:bg-neutral-50 focus:bg-neutral-50 text-black': lightNoActiveChange },
      { 'hover:opacity-50': transparent },
      { 'disabled-ui-button': (disabled && !petrol) && (disabled && !transparent) && (disabled && !light) },
      { 'opacity-50': disabled && transparent },
      { 'rounded-xl': !props.buttonstyle && !link },
      { 'px-3 py-3': !props.buttonpadding && !link },
      props.buttonstyle, props.buttonpadding
    ]"
    @click="props.moveRef !== null ? scrollSectionIntoView(props.moveRef, props.moveRefOffset) : null"
  >
    <span v-if="$slots.icon" class="mr-1">
      <slot name="icon" />
    </span>
    <span v-if="$slots">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: 'petrol'
  },
  buttonstyle: {
    type: [String, Array],
    default: ''
  },
  buttonpadding: {
    type: [String, Array],
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  to: {
    type: String,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  title: {
    type: String,
    default: null
  },
  moveRef: {
    type: String,
    default: null
  },
  moveRefOffset: {
    type: Number,
    default: 24
  }
})

const is = computed(() => props.to ? resolveComponent('NuxtLink') : (props.href ? 'a' : 'button'))

const cta = computed(() => props.color === 'cta')
const primary = computed(() => props.color === 'primary')
const primaryHighContrast = computed(() => props.color === 'primary-high-contrast')
const petrol = computed(() => props.color === 'petrol')
const orange = computed(() => props.color === 'orange')
const success = computed(() => props.color === 'success')
const error = computed(() => props.color === 'error')
const light = computed(() => props.color === 'light')
const link = computed(() => props.color === 'link')
const lightNoActiveChange = computed(() => props.color === 'light-no-active-change')
const transparent = computed(() => props.color === 'transparent')
const disabled = computed(() => props.disabled === true)

const scrollSectionIntoView = (refName: string, offset: number = 24) => {
  const element = useRefState(refName).ref?.value?.$el
  const topPosition = element ? element.getBoundingClientRect().top + window.scrollY - offset : 0
  window.scrollTo({ top: topPosition, behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
.disabled-ui-button {
  @apply bg-neutral-200 border-neutral-200 pointer-events-none select-none;
}
</style>
